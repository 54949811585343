import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useState, type ReactNode } from "react";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";

import request from "util/request";
import { segmentTrack } from "util/segment";
import { hardNavigateToUnsafe } from "util/navigation";
import { Heading, Paragraph } from "common/core/typography";

import { BackButton, FooterButton, Continue } from "./common";
import SAMLStyles from "./saml.module.scss";
import Styles from "./index.module.scss";
import type { PasswordScreen } from "./password";
import type { EmailScreen } from "./email";
import ProofCard from "../card";

export type SsoProvider = {
  id: string;
  type: string;
  organization: {
    name: string;
    logoUrl: string | null;
  };
};

type SsoLoginInput = {
  email: string;
  ssoProvider: SsoProvider;
  redirectUrl: string | null | undefined;
};

export type SSOScreen = {
  type: "sso";
  ssoProvider: SsoProvider;
  email: string;
  passwordAuthEnabled: boolean;
};

const MESSAGES = defineMessages({
  backButtonAriaLabel: {
    id: "7f08ed50-4081-4c53-90a8-e29fac9abb8d",
    defaultMessage: "Back to email input",
  },
  InvalidEmail: {
    id: "04790f4f-0358-4fe6-9144-6612fd060bad",
    defaultMessage: "Invalid email address",
  },
  InvalidIdentityProvider: {
    id: "0882afae-46c8-437a-9447-484e9d6f5108",
    defaultMessage: "Invalid identity provider. Please contact your administrator.",
  },
  InvalidLogoutRequest: {
    id: "8b31656d-2fa0-4ff8-b560-7a2891cd29af",
    defaultMessage: "Invalid logout request. Please contact your company's IT department.",
  },
  InvalidRedirectUrl: {
    id: "0adb7e5b-8e05-4a84-b237-c2e7e24cbc4b",
    defaultMessage: "Invalid redirect URL. Please contact your company's IT department.",
  },
  InvalidOrganization: {
    id: "381a59b5-acb8-4472-ac75-05ea86dd4514",
    defaultMessage: "Invalid organization. Please contact your company's IT department.",
  },
  InvitationRequired: {
    id: "ecf490fc-0d98-48d0-b1b0-991b5a922d48",
    defaultMessage:
      "A Proof account with this email address already exists. Reach out to your admin to invite you as a team member.",
  },
  ProviderAlreadyConnected: {
    id: "30e295b3-867e-452f-aae0-757f59d86263",
    defaultMessage:
      "A Proof account with this email address already exists in a different organization. Reach out to support if you need to switch organizations.",
  },
  catchAll: {
    id: "e75b13cc-9262-473a-98f5-4038c4984e2c",
    defaultMessage: "Please contact Proof support.",
  },
});

function ssoLogin({ email, ssoProvider, redirectUrl }: SsoLoginInput): Promise<void> {
  segmentTrack("Attempted Saml Login");
  return request("post", "saml/login", {
    email,
    redirect_url: redirectUrl,
    identity_provider_id: ssoProvider.id,
  }).then((json) => hardNavigateToUnsafe(json.idp_url));
}

const translateErrorMessage = (key: string) => {
  if (key in MESSAGES) {
    return MESSAGES[key as keyof typeof MESSAGES];
  }

  return MESSAGES.catchAll;
};

export function ErrorMessage({ errorMsg }: { errorMsg: string }) {
  const intl = useIntl();
  return (
    <div className={classNames(Styles.errorMessage)}>
      <Paragraph>
        <FormattedMessage
          id="f1a01521-9c76-4bc9-b688-f7b3fcc47cf1"
          defaultMessage="Your Single Sign-On authentication has failed."
        />
      </Paragraph>
      <Paragraph>{intl.formatMessage(translateErrorMessage(errorMsg))}</Paragraph>
    </div>
  );
}

function SamlLoginHeader({ ssoProvider }: { ssoProvider: SsoProvider }) {
  const logo = ssoProvider.organization.logoUrl;

  return (
    <div aria-live="polite" className={SAMLStyles.flexColumn}>
      {logo && (
        <img
          className={SAMLStyles.ssoLogo}
          src={logo}
          alt={`${ssoProvider.organization.name} logo`}
        />
      )}
      <div className={SAMLStyles.ssoHeading}>
        <Heading level="h1" textStyle="headingFour">
          <FormattedMessage
            id="43999515-4960-4dc7-895a-0bbb9841ab3a"
            defaultMessage="Single sign-on to"
            tagName="span"
          />
          &nbsp;
          <span className={SAMLStyles.ssoCompanyName}>
            <FormattedMessage
              id="9465308a-218c-4373-ba6e-bf538044c898"
              defaultMessage={`<b>{name}</b>`}
              values={{
                b: (msg: ReactNode[]) => <strong>{msg}</strong>,
                name: ssoProvider.organization.name,
              }}
            />
          </span>
        </Heading>
      </div>
      <Heading level="h2" textStyle="subtitle" className={SAMLStyles.ssoSecondaryHeading}>
        <FormattedMessage
          id="8d6e35d8-c10f-48d9-bd46-86229c08c3df"
          defaultMessage="Authenticate your account by logging into {name}’s single sign-on provider"
          values={{ name: ssoProvider.organization.name }}
        />
      </Heading>
    </div>
  );
}

type Props = {
  passwordAuthEnabled: boolean;
  onNextScreen(screen: PasswordScreen | EmailScreen): void;
  email: string;
  ssoProvider: SsoProvider;
};

export default function SamlScreenCard({
  email,
  ssoProvider,
  passwordAuthEnabled,
  onNextScreen,
}: Props) {
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect") || "/portal-gateway";
  const [samlError, setSamlError] = useState<string | null>(null);
  const intl = useIntl();

  const onClick = () => {
    ssoLogin({ email, ssoProvider, redirectUrl: decodeURIComponent(redirect) }).catch(
      (error: { body: { error: string } }) => setSamlError(error.body.error),
    );
  };

  return (
    <ProofCard
      body={
        <>
          <SamlLoginHeader ssoProvider={ssoProvider} />
          <Continue onClick={onClick} />
          {samlError && <ErrorMessage errorMsg={samlError} />}
        </>
      }
      footer={
        <div className={classNames(Styles.footerCentered)}>
          <BackButton
            onClick={() => onNextScreen({ type: "email", email })}
            aria-label={intl.formatMessage(MESSAGES.backButtonAriaLabel)}
          />
          {passwordAuthEnabled && (
            <FooterButton
              variant="tertiary"
              buttonColor="action"
              onClick={() => onNextScreen({ type: "password", email })}
            >
              <FormattedMessage
                id="e9b64870-2d20-4a3b-93d9-3174a819dbf6"
                defaultMessage="Use password instead"
              />
            </FooterButton>
          )}
        </div>
      }
    />
  );
}

import { type ChangeEvent, useCallback, useState, type ComponentProps } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import RevokeNotaryCertificateMutation from "common/notary/certificate_management/revoke_notary_proof_cert_mutation.graphql";
import FormattedAddress from "common/core/format/address";
import { CustomFormattedDateTime } from "common/core/format/date";
import { DetailsRow, DetailsRowHeader, BinaryStatusWithToggle } from "common/notary/details";
import MultiSelectInput from "common/form/inputs/multi_select";
import { MailtoLink } from "common/core/mailto_link";
import Icon from "common/core/icon";
import Tooltip from "common/core/tooltip";
import Button from "common/core/button";
import { SelectInput } from "common/form/inputs/select";
import { useMutation } from "util/graphql";
import {
  ValidationRequirements,
  NotaryOnboardingStatuses,
  NotaryAccountStatuses,
  NotaryCertificateProvider,
  CertificateMigrationStatus,
  AuthTypes,
  type Language,
  type NotaryComplianceStatuses,
  type RevocationReason,
} from "graphql_globals";
import FormattedPrice from "common/core/format/formatted_price";
import { usePermissions } from "common/core/current_user_role";
import { isWisconsinAttorney } from "common/notary/profile_wizard/section/section_utils";
import { LegalTeamEmailEdit } from "admin_portal/common/legal_team_email_edit";
import { simpleAssetUpload } from "util/uploader";
import RevokeCertModal from "common/notary/certificate_management/revoke_cert_modal";
import { isGraphQLError } from "util/graphql/query";
import { captureException } from "util/exception";

import { ONBOARDING_STATUSES, NotaryCapacitiesSummary, useConfiguration } from "../util";
import type { NotaryInfo as NotaryUser } from "./notary_info_fragment.graphql";
import UpdateNotaryProfileMutation from "./update_notary_profile_mutation.graphql";
import type { NotaryList_viewer } from "../notary_list_query.graphql";
import UpdateNotaryProfileLanguagesMutation from "./update_notary_profile_languages_mutation.graphql";
import UpdateNotaryComplianceMutation from "./update_notary_compliance_mutation.graphql";
import ToggleNotaryAccountStatusMutation from "./toggle_notary_account_status_mutation.graphql";
import Styles from "./notary_info.module.scss";

type Props = {
  canUpdateNotary: boolean;
  notaryUser: NotaryUser;
  notaryUsStates: NotaryList_viewer["notaryUsStates"];
};

const NA_ELEM = <FormattedMessage id="525d1835-c7bd-4ee4-88cd-8c925fd8b298" defaultMessage="N/A" />;

const SAVING_INDICATOR = (
  <div className="NotaryDetailsModal--Info--SavingIndicator">
    <FormattedMessage id="78f84b5c-6c0e-4bdd-afed-5113c1eb0094" defaultMessage="Saving..." />
  </div>
);
const renderDate = (value: ComponentProps<typeof CustomFormattedDateTime>["value"]) => (
  <CustomFormattedDateTime formatStyle="PPP" value={value} />
);

const messages = defineMessages({
  notaryIdLabel: {
    id: "77c69238-64b4-4bf3-966e-baefc90ce2ee",
    defaultMessage: "More details about the Notary Profile ID",
  },
  tierStatusLabel: {
    id: "77c69238-64b4-4bf3-966e-baefc90ce2ee",
    defaultMessage: "More details about the tier status",
  },
  notaryAccountStatusLabel: {
    id: "4b46e4df-961f-4e52-9aaa-f990fbc06e28",
    defaultMessage: "More details about the profile status",
  },
  notaryComplianceStatusLabel: {
    id: "58c7358c-72b3-48dd-bc8b-5785d12979f1",
    defaultMessage: "More details about the compliance status",
  },
});

function useUsStateSelection(user: NotaryUser) {
  const [isSaving, setIsSaving] = useState(false);
  const updateNotaryProfileMutateFn = useMutation(UpdateNotaryProfileMutation);
  const handleChange = useCallback(
    (usStateId: string) => {
      setIsSaving(true);
      updateNotaryProfileMutateFn({
        variables: {
          input: {
            id: user.notaryProfile!.id,
            usStateId,
          },
        },
      }).finally(() => {
        setIsSaving(false);
      });
    },
    [user],
  );
  return {
    isSaving,
    handleChange,
  };
}

function useCertificateSelection(notaryProfile: NotaryUser["notaryProfile"]) {
  const [isSaving, setIsSaving] = useState(false);
  const updateNotaryProfileMutateFn = useMutation(UpdateNotaryProfileMutation);
  const handleChange = useCallback(
    (certificateProvider: NotaryCertificateProvider) => {
      setIsSaving(true);
      updateNotaryProfileMutateFn({
        variables: {
          input: {
            id: notaryProfile!.id,
            certificateProvider,
          },
        },
      }).finally(() => {
        setIsSaving(false);
      });
    },
    [updateNotaryProfileMutateFn, notaryProfile],
  );
  return {
    isSaving,
    handleChange,
  };
}

function useLanguageSelection(notaryProfile: NotaryUser["notaryProfile"]) {
  const [isSaving, setIsSaving] = useState(false);
  const updateLanguagesMutateFn = useMutation(UpdateNotaryProfileLanguagesMutation);
  const handleChange = useCallback(
    (value: { value: Language }[]) => {
      const languages = value.map((item) => item.value);
      if (!languages.length) {
        return;
      }
      setIsSaving(true);
      updateLanguagesMutateFn({
        variables: {
          input: {
            notaryProfileId: notaryProfile!.id,
            languages,
          },
        },
      }).finally(() => {
        setIsSaving(false);
      });
    },
    [notaryProfile],
  );
  return {
    isSaving,
    handleChange,
  };
}

function useComplianceStatusSelection(notaryProfile: NotaryUser["notaryProfile"]) {
  const [isSaving, setIsSaving] = useState(false);
  const updateNotaryComplianceMutateFn = useMutation(UpdateNotaryComplianceMutation);
  const handleChange = useCallback(
    (complianceStatus: NotaryComplianceStatuses) => {
      setIsSaving(true);
      updateNotaryComplianceMutateFn({
        variables: {
          input: {
            notaryProfileId: notaryProfile!.id,
            complianceStatus,
          },
        },
      }).finally(() => {
        setIsSaving(false);
      });
    },
    [notaryProfile],
  );
  return {
    isSaving,
    handleChange,
  };
}

function useSealSelection(notaryProfile: NotaryUser["notaryProfile"]) {
  const updateNotaryProfileMutateFn = useMutation(UpdateNotaryProfileMutation);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const [asset] = target.files!;
    simpleAssetUpload({ asset }).then((seal) => {
      return updateNotaryProfileMutateFn({
        variables: {
          input: { id: notaryProfile!.id, seal },
        },
      });
    });
  };
  return {
    handleChange,
  };
}

function useAccountStatusSelection(notaryProfile: NotaryUser["notaryProfile"]) {
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const updateNotaryAccountStatusMutateFn = useMutation(ToggleNotaryAccountStatusMutation);
  const toggle = useCallback(() => {
    setError(null);
    setIsSaving(true);
    updateNotaryAccountStatusMutateFn({
      variables: {
        input: {
          notaryProfileId: notaryProfile!.id,
          accountStatus:
            notaryProfile!.accountStatus === NotaryAccountStatuses.DISABLED
              ? NotaryAccountStatuses.ENABLED
              : NotaryAccountStatuses.DISABLED,
        },
      },
    })
      .catch((error) => {
        if (!isGraphQLError(error)) {
          captureException(error);
        }
        const err = (isGraphQLError(error) ? error.graphQLErrors[0] : error) as Error;
        setError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [notaryProfile]);
  return {
    isSaving,
    toggle,
    accountEnabled: notaryProfile!.accountStatus === NotaryAccountStatuses.ENABLED,
    error,
  };
}

function useMigrationStatusSelection(notaryProfile: NotaryUser["notaryProfile"]) {
  const [isSaving, setIsSaving] = useState(false);
  const updateNotaryProfileMutateFn = useMutation(UpdateNotaryProfileMutation);
  const toggle = useCallback(() => {
    setIsSaving(true);
    updateNotaryProfileMutateFn({
      variables: {
        input: {
          id: notaryProfile!.id,
          certificateMigrationStatus:
            notaryProfile!.certificateMigrationStatus ===
              CertificateMigrationStatus.LEGACY_CERTIFICATE ||
            notaryProfile!.certificateMigrationStatus ===
              CertificateMigrationStatus.LEGACY_CERTIFICATE_NO_MIGRATION
              ? CertificateMigrationStatus.PROOF_CERTIFICATE
              : CertificateMigrationStatus.LEGACY_CERTIFICATE_NO_MIGRATION,
        },
      },
    }).finally(() => {
      setIsSaving(false);
    });
  }, [notaryProfile]);

  return {
    isSaving,
    toggle,
    proofCertEnabled:
      notaryProfile!.certificateMigrationStatus === CertificateMigrationStatus.PROOF_CERTIFICATE,
  };
}

const CERTIFICATE_PROVIDERS = Object.freeze({
  [NotaryCertificateProvider.IDENTRUST]: (
    <FormattedMessage id="1ade8524-c7ca-4c49-ace9-f6d9e3eb47dd" defaultMessage="Identrust" />
  ),
});

function GeneralInfo({ notaryUser, canUpdateNotary }: Omit<Props, "notaryUsStates">) {
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const hasLegalEditPermission = hasPermissionFor("privacyEmailEdit");
  const { organization } = notaryUser;
  const notaryProfile = notaryUser.notaryProfile!;
  const complianceStatusSelection = useComplianceStatusSelection(notaryProfile);
  const langSelection = useLanguageSelection(notaryProfile);
  const accountEnablementSelection = useAccountStatusSelection(notaryProfile);
  const { languagesDropdownItems, complianceStatusDropdownItems } = useConfiguration();

  const canUpdateCompliance = notaryProfile.onboardingStatus === NotaryOnboardingStatuses.COMPLETED;
  const hasMFASMS = notaryUser.authenticationRequirements.some(
    (req) => req.authType === AuthTypes.SMS,
  );

  return (
    <>
      <DetailsRowHeader>
        <FormattedMessage
          id="4c387845-c9ff-4b99-9b9f-9d8d737dd529"
          defaultMessage="Notary Details"
        />
      </DetailsRowHeader>
      <DetailsRow
        label={
          <>
            <FormattedMessage
              id="232edb3f-3d99-4ca7-a725-ab14487e2ef7"
              defaultMessage="Compliance Status"
            />
            <Tooltip
              target={<Icon name="question" />}
              placement="top"
              triggerButtonLabel={intl.formatMessage(messages.notaryComplianceStatusLabel)}
            >
              <FormattedMessage
                id="9a2b324b-bc08-42cd-b49d-d4403f41a572"
                defaultMessage="Notaries can't take meetings until compliant. Update compliance status after a notary has completed onboarding or edited their profile."
              />
            </Tooltip>
            {complianceStatusSelection.isSaving && SAVING_INDICATOR}
          </>
        }
      >
        <SelectInput
          disabled={!canUpdateCompliance || !canUpdateNotary}
          value={notaryProfile.complianceStatus}
          onChange={complianceStatusSelection.handleChange}
          items={complianceStatusDropdownItems}
          data-automation-id="notary-compliance-input-field"
        />
      </DetailsRow>
      <DetailsRow
        label={
          <>
            <span id="onboarding-status-toggle">
              <FormattedMessage
                id="67a22e58-45e8-4ca9-9bad-b35f8d767559"
                defaultMessage="Onboarding Status"
              />
            </span>
          </>
        }
      >
        <BinaryStatusWithToggle
          value={notaryProfile.onboardingStatus === NotaryOnboardingStatuses.COMPLETED}
          customLabel={ONBOARDING_STATUSES[notaryProfile.onboardingStatus]}
          automationId={"onboardingStatus"}
          aria-labelledby="onboarding-status-toggle"
        />
      </DetailsRow>
      <DetailsRow
        label={
          <>
            <span id="notary-account-toggle">
              <FormattedMessage
                id="5a6a0d79-a83f-4f75-a117-5e0a8138206c"
                defaultMessage="Account Status"
              />
            </span>
            <Tooltip
              target={<Icon name="question" />}
              placement="top"
              triggerButtonLabel={intl.formatMessage(messages.notaryAccountStatusLabel)}
            >
              <FormattedMessage
                id="d2ec9270-211b-4e9a-b1a6-cec9c521ef0b"
                defaultMessage="Disabled notaries cannot take meetings and will not receive automated emails. They can only access the notary journal."
              />
            </Tooltip>
          </>
        }
      >
        <BinaryStatusWithToggle
          automationId="notaryAccountEnabled"
          onChange={canUpdateNotary ? accountEnablementSelection.toggle : undefined}
          value={accountEnablementSelection.accountEnabled}
          aria-labelledby="notary-account-toggle"
          customLabel={
            <>
              <FormattedMessage
                id="f0cc9c64-284a-4ac3-85ae-256279983c5d"
                defaultMessage="{enabled, select, true {Active Notary} other {Disabled Notary}}"
                values={{ enabled: accountEnablementSelection.accountEnabled }}
              />
              {accountEnablementSelection.error && (
                <div className={Styles.disabledError}>
                  <FormattedMessage
                    id="7ffb179b-584a-47b6-9182-ea1158bd0a50"
                    defaultMessage="Failed to {action} account. {message}"
                    values={{
                      action: accountEnablementSelection.accountEnabled ? "disable" : "enable",
                      message: accountEnablementSelection.error.message,
                    }}
                  />
                </div>
              )}
            </>
          }
        />
      </DetailsRow>
      <DetailsRow
        label={
          <>
            <FormattedMessage
              id="86c4795d-1058-4c83-832d-c466fef11f6d"
              defaultMessage="Notary Profile ID"
            />
            <Tooltip
              target={<Icon name="question" />}
              placement="top"
              triggerButtonLabel={intl.formatMessage(messages.notaryIdLabel)}
            >
              <FormattedMessage
                id="5354e952-eefa-4449-873b-118d74506cd0"
                defaultMessage="(Internal) This is helpful when reporting bugs/issues with the product or for Launch Darkly."
              />
            </Tooltip>
          </>
        }
      >
        {notaryProfile.id}
      </DetailsRow>
      <DetailsRow
        label={
          <FormattedMessage
            id="74c4d68b-78a0-4ad8-9983-59cab0e8ea7c"
            defaultMessage="Email Address"
          />
        }
      >
        {hasLegalEditPermission ? (
          <div className="LegalEmailEditContainer">
            <LegalTeamEmailEdit
              userEmail={notaryUser.pendingEmail || "No email set"}
              userId={notaryUser.id}
            />
          </div>
        ) : (
          <MailtoLink emailAddress={notaryUser.email!} text={notaryUser.email} />
        )}
      </DetailsRow>
      <DetailsRow
        label={
          <FormattedMessage
            id="eca33fc5-da70-4f67-bc34-64225a7926af"
            defaultMessage="Compliance Phone Number"
          />
        }
      >
        {notaryProfile.phone}
      </DetailsRow>
      {hasMFASMS && (
        <DetailsRow
          label={
            <FormattedMessage
              id="489eee8a-605c-442e-8a11-c7d0940c1b3c"
              defaultMessage="MFA Phone Number"
            />
          }
        >
          {notaryUser.phone?.number}
        </DetailsRow>
      )}
      <DetailsRow
        label={
          <FormattedMessage id="4045cd0a-88eb-4a8c-8cd5-24437492d4a4" defaultMessage="Address" />
        }
      >
        {notaryProfile.address.line1 ? (
          <FormattedAddress address={notaryProfile.address} />
        ) : (
          NA_ELEM
        )}
      </DetailsRow>
      <DetailsRow
        label={
          <FormattedMessage id="41562204-74a8-4fe8-8b84-fde404d75da6" defaultMessage="Capacities" />
        }
      >
        <div>
          <NotaryCapacitiesSummary capacities={notaryProfile.capacities} />
        </div>
      </DetailsRow>
      <DetailsRow
        label={
          <FormattedMessage id="744cf534-e266-49e3-8278-25553f09d7e7" defaultMessage="Company" />
        }
      >
        {organization?.name}
      </DetailsRow>
      <DetailsRow
        label={
          <>
            <FormattedMessage
              id="97a6372b-cdcf-4b1d-8c06-5782c170b7a1"
              defaultMessage="Language Support"
            />
            {langSelection.isSaving && SAVING_INDICATOR}
          </>
        }
      >
        <MultiSelectInput
          items={languagesDropdownItems}
          onChange={langSelection.handleChange}
          value={notaryProfile.languages}
        />
      </DetailsRow>
    </>
  );
}

export default function NotaryInfo({ notaryUser, notaryUsStates, canUpdateNotary }: Props) {
  const notaryProfile = notaryUser.notaryProfile!;
  const usStateSelection = useUsStateSelection(notaryUser);
  const certificateSelection = useCertificateSelection(notaryProfile);
  const sealSelection = useSealSelection(notaryProfile);
  const usStatesList = notaryUsStates.map((state) => ({ value: state!.id, label: state!.name }));
  const currentUsState = usStatesList.find((state) => state.label === notaryProfile.usState.name);

  const proofCertificate = notaryProfile.proofCertificate;
  const proofCertificateSubject = proofCertificate?.subject;

  const migratedToProof =
    notaryProfile.certificateMigrationStatus === CertificateMigrationStatus.PROOF_CERTIFICATE;
  const reqLookup = new Set(notaryProfile.requirements);
  const [showRevocationModal, setShowRevocationModal] = useState(false);
  const { hasPermissionFor } = usePermissions();
  const certificateProviderDropdownItems = migratedToProof
    ? [
        {
          value: "PROOF",
          label: (
            <FormattedMessage id="27fe11fc-f480-4237-a804-6d1e7e9c120c" defaultMessage="Proof" />
          ),
        },
      ]
    : Object.entries(CERTIFICATE_PROVIDERS).map(([value, label]) => {
        return { value: value as NotaryCertificateProvider, label };
      });

  const certMigrationSelection = useMigrationStatusSelection(notaryProfile);
  const revokeNotaryCertificateMutateFn = useMutation(RevokeNotaryCertificateMutation);

  const onRevoke = async (reason: RevocationReason) => {
    await revokeNotaryCertificateMutateFn({
      variables: {
        input: {
          notaryProfileId: notaryProfile.id,
          revocationReason: reason,
        },
      },
    });
    setShowRevocationModal(false);
  };

  return (
    <div className="NotaryDetailsInfo">
      <GeneralInfo notaryUser={notaryUser} canUpdateNotary={canUpdateNotary} />
      <DetailsRowHeader>
        <FormattedMessage
          id="cbd1e4b7-8447-4776-b535-f812ec91dcc9"
          defaultMessage="Commission Details"
        />
      </DetailsRowHeader>

      {reqLookup.has(ValidationRequirements.COMMISSION_NUMBER) && (
        <DetailsRow
          label={
            <FormattedMessage
              id="108d8519-5989-4734-a24a-c1c7632edbff"
              defaultMessage="Commission Number"
            />
          }
        >
          {notaryProfile.notaryId}
        </DetailsRow>
      )}

      {(reqLookup.has(ValidationRequirements.COMMISSION_EXPIRATION) ||
        isWisconsinAttorney(notaryProfile)) && (
        <>
          <DetailsRow
            label={
              <FormattedMessage
                id="375c3aaa-b9f8-489d-8f43-a5834f14800d"
                defaultMessage="Commission Expiration Date"
              />
            }
          >
            {isWisconsinAttorney(notaryProfile) ? (
              <FormattedMessage
                id="7569307c-355f-4bb5-8b38-f447ab597c9a"
                defaultMessage="Permanent"
              />
            ) : (
              renderDate(notaryProfile.licenseExpiry)
            )}
          </DetailsRow>
        </>
      )}

      <DetailsRow
        label={
          <FormattedMessage
            id="46c7217b-83df-465c-a725-ba0d672eb8ee"
            defaultMessage="Commissioning State"
          />
        }
      >
        <SelectInput
          value={currentUsState}
          disabled={notaryProfile.hasCompletedMeetings || !canUpdateNotary}
          items={usStatesList}
          data-automation-id="notary-state-field"
          onChange={usStateSelection.handleChange}
        />
      </DetailsRow>

      {reqLookup.has(ValidationRequirements.COMMISSION_COUNTY) && (
        <DetailsRow
          label={
            <FormattedMessage id="77951098-6ec6-4bc5-a7e8-fc438e1a259a" defaultMessage="County" />
          }
        >
          {notaryProfile.county}
        </DetailsRow>
      )}
      <DetailsRowHeader>
        <FormattedMessage
          id="c5b99bc7-1273-45ce-a83a-ecb3d8f2c12e"
          defaultMessage="Insurance & Other Details"
        />
      </DetailsRowHeader>

      {reqLookup.has(ValidationRequirements.POLICY_DETAILS) && (
        <>
          <DetailsRow
            label={
              <FormattedMessage
                id="3539e941-f5f4-4b31-9243-14baa12dab7b"
                defaultMessage="Errors & Omissions Insurer"
              />
            }
          >
            {notaryProfile.insurer}
          </DetailsRow>
          <DetailsRow
            label={
              <FormattedMessage
                id="edd85916-500a-4c6b-b812-e9ad218ab5db"
                defaultMessage="Errors & Omissions Insurance Amount"
              />
            }
          >
            {notaryProfile.policyAmountInCents && (
              <FormattedPrice cents={notaryProfile.policyAmountInCents} hideFractionDigits />
            )}
          </DetailsRow>
          <DetailsRow
            label={
              <FormattedMessage
                id="e20ff997-c418-4f9b-9756-313e6cee5a69"
                defaultMessage="Errors & Omissions Policy Expiration Date"
              />
            }
          >
            {renderDate(notaryProfile.policyExpiry)}
          </DetailsRow>
        </>
      )}

      {reqLookup.has(ValidationRequirements.EDUCATION_EXPIRATION_DATE) && (
        <DetailsRow
          label={
            <FormattedMessage
              id="6c242463-de81-4aa9-a8ff-a966d04fa433"
              defaultMessage="Education Expiration Date"
            />
          }
        >
          {renderDate(notaryProfile.educationExpirationDate)}
        </DetailsRow>
      )}

      <DetailsRowHeader>
        <FormattedMessage
          id="f5bfabf0-9eaf-42bc-ba96-8053da42c76e"
          defaultMessage="Digital Certificate Details"
        />
      </DetailsRowHeader>
      <DetailsRow
        label={
          <FormattedMessage id="bb758597-a3bf-4c19-a712-d618f1fe31ba" defaultMessage="Provider" />
        }
      >
        <SelectInput
          value={migratedToProof ? "PROOF" : notaryProfile.certificateProvider}
          items={certificateProviderDropdownItems}
          data-automation-id="notary-certificate-providers"
          onChange={certificateSelection.handleChange}
          disabled={!hasPermissionFor("notaryCertificateAuthority") || migratedToProof}
        />
      </DetailsRow>
      <DetailsRow
        label={
          <>
            <FormattedMessage
              id="d45aa3d4-468b-4863-97b3-096f3642309b"
              defaultMessage="Enable Proof Digital Certificate"
            />
            {certMigrationSelection.isSaving && SAVING_INDICATOR}
          </>
        }
      >
        <BinaryStatusWithToggle
          onChange={canUpdateNotary ? certMigrationSelection.toggle : undefined}
          value={certMigrationSelection.proofCertEnabled}
          aria-labelledby="notary-proof-cert-toggle"
          customLabel={
            <FormattedMessage
              id="605c3cf9-8eb8-43ea-aeb2-27269ba2f36a"
              defaultMessage="Proof Certificate {enabled, select, true {Enabled} other {Disabled}}"
              values={{ enabled: certMigrationSelection.proofCertEnabled }}
            />
          }
        />
      </DetailsRow>
      {migratedToProof && (
        <DetailsRow
          label={
            <FormattedMessage
              id="4cd90638-94bc-407e-b069-91134c3f0ca4"
              defaultMessage="Revoke Proof Digital Certificate"
            />
          }
        >
          {proofCertificate?.revokedAt ? (
            <span className={Styles.revokedAt}>
              <FormattedMessage
                id="9c5ab6b9-ad18-4a44-bada-27599ea4e394"
                defaultMessage="Revoked on {revokedAt}"
                values={{ revokedAt: renderDate(proofCertificate.revokedAt) }}
              />
            </span>
          ) : proofCertificate ? (
            <div>
              <Button
                className={Styles.revokeButton}
                onClick={() => {
                  setShowRevocationModal(true);
                }}
                buttonColor="danger"
                variant="secondary"
                withIcon={{ name: "delete", placement: "left" }}
              >
                <FormattedMessage
                  id="72cd146f-9cf6-463e-9ad9-aa09ff0a38c8"
                  defaultMessage="Revoke Proof Certificate"
                />
              </Button>
            </div>
          ) : (
            "--"
          )}
        </DetailsRow>
      )}

      {reqLookup.has(ValidationRequirements.CERTIFICATE) && (
        <>
          <DetailsRow
            label={
              <FormattedMessage
                id="cd29130a-e928-4293-bffe-153867342482"
                defaultMessage="Expiration Date"
              />
            }
          >
            {renderDate(
              notaryProfile.certificateMigrationStatus ===
                CertificateMigrationStatus.PROOF_CERTIFICATE
                ? proofCertificate?.validTo
                : notaryProfile.certExpiry,
            )}
          </DetailsRow>
          <DetailsRow
            label={
              <FormattedMessage
                id="0b8f1437-004b-4c5a-a8de-0841481655b8"
                defaultMessage="Subject Common Name"
              />
            }
          >
            {notaryProfile.certificateMigrationStatus ===
            CertificateMigrationStatus.PROOF_CERTIFICATE
              ? proofCertificateSubject
              : notaryProfile.certSubjectCommonName}
          </DetailsRow>
        </>
      )}

      {reqLookup.has(ValidationRequirements.SIGNING_ASSETS) && (
        <>
          <DetailsRowHeader>
            <FormattedMessage
              id="cc91326b-9146-448b-ad71-40ab7277171a"
              defaultMessage="Signature Details"
            />
          </DetailsRowHeader>
          <DetailsRow
            largeChild
            label={
              <FormattedMessage
                id="f4c83451-5c63-45d6-a9fb-c3eed6806e82"
                defaultMessage="Signature"
              />
            }
          >
            {notaryProfile.signatureInfo?.assetUrl?.url && (
              <img src={notaryProfile.signatureInfo.assetUrl.url} alt="signature" />
            )}
          </DetailsRow>
          <DetailsRow
            largeChild
            label={
              <FormattedMessage
                id="08152082-0b2e-48b3-8adf-728b786c0c52"
                defaultMessage="Initials"
              />
            }
          >
            {notaryProfile.initialsInfo?.assetUrl?.url && (
              <img src={notaryProfile.initialsInfo.assetUrl.url} alt="initials" />
            )}
          </DetailsRow>
          <DetailsRow
            largeChild
            label={
              <FormattedMessage id="2f7c7f7e-7063-408c-bfac-ce6faa8c4b94" defaultMessage="Seal" />
            }
          >
            {notaryProfile.sealInfo?.assetUrl?.url && (
              <img src={notaryProfile.sealInfo.assetUrl.url} alt="seal" />
            )}
          </DetailsRow>
          <DetailsRow
            label={
              <FormattedMessage
                id="bb758597-a3bf-4c19-a712-d618f1fe31ba"
                defaultMessage="Update Seal"
              />
            }
          >
            <input type="file" accept="image/png" onChange={sealSelection.handleChange} />
          </DetailsRow>
        </>
      )}
      {showRevocationModal && (
        <RevokeCertModal
          proofCert={proofCertificate!}
          onCancel={() => setShowRevocationModal(false)}
          onRevoke={onRevoke}
          certBelongsToCurrentUser={false}
        />
      )}
    </div>
  );
}
